import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import ArticlePreview from "../components/ArticlePreview";
import PageInfo from "../components/PageInfo";
import slugify from "slugify";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";
import "../assets/styles/catalogues.css";

const LayoutWrapper = styled.div`
  // background-color: white;
  position: relative;
  color: black;
`;

const ArticlesWrapper = styled.div`
  background-color: yellow;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  margin-bottom: 100px;
  // padding: 80px 50px 0;
  // max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  div {
    height: 100%;
    width: 100%;
    background-color: yellow;
  }
`;

const pageData = {
  header: "Katalogi",
  mainTitle: "Zapoznaj się",
  subtitle: "Z aktualną ofertą JB Multimedia",
};

const Catalouges = () => {
  const posts = [
    {
      id: 1,
      title: "Tworzymy przyszłość",
      excerpt: "Dzięki innowacyjnym rozwiązaniom!",
      image: require("../assets/images/catalogues/catalogue1_img.webp").default,
      link: "https://heyzine.com/flip-book/d265bdea4d.html",
    },
    {
      id: 2,
      title: "Jura Pro 2024",
      excerpt: "Katalog dla firm",
      image: require("../assets/images/catalogues/jura-pro-catalogue.webp")
        .default,
      link: "https://heyzine.com/flip-book/2b9d76f8c4.html",
    },
    {
      id: 3,
      title: "Jura Retail 2024",
      excerpt: "Katalog dla Ciebie",
      image: require("../assets/images/catalogues/jura-retail-catalogue.webp")
        .default,
      link: "https://heyzine.com/flip-book/bdf2cdcf81.html",
    },
    {
      id: 4,
      title: "Ambasada kawy",
      excerpt: "Odwiedź świat kaw Speciality Salvatti",
      image: require("../assets/images/catalogues/coffee-catalogue.webp")
        .default,
      link: "/ambasada-kawy",
    },
  ];

  return (
    <>
      <PageInfo
        header={pageData.header}
        mainTitle={pageData.mainTitle}
        // date={pageData.date}
        subtitle={pageData.subtitle}
      />
      <Layout>
        <section className="news-page catalogues">
          <LayoutWrapper className="news-page-container">
            <div className="blog-subpage">
              {posts.map((post) => (
                <div key={post.id} className="post-card">
                  <img
                    src={post.image}
                    alt={post.title}
                    className="post-image"
                  />
                  <div className="post-info">
                    <h2 className="post-title">{post.title}</h2>
                    <p className="post-excerpt">{post.excerpt}</p>
                    <Link
                      target="_blank"
                      rel="noreferrer noopener "
                      to={post.link}
                    >
                      <button className="btn-red">Czytaj więcej</button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </LayoutWrapper>
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>
          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo
    title="Katalogi"
    description="Katalogi prezentujące produkty z naszej oferty"
  />
);

export default Catalouges;
